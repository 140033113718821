import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const ArticleDate = styled.h5`
  display: inline;
  color: #606060;
`

const MarkerHeader = styled.h3`
  display: inline;
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  );
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Experience" />
      <Content>
        <h1 css={css`text-align: center`}>Experience</h1>
        <a
          href="https://outintech.com/u/mentorship/"
          css={css`
            text-decoration: none;
            color: inherit;
            display: grid;
            grid-row-gap: 8px;
            justify-content: center;
            text-align: center;
            margin-bottom: 16px;
            padding: 3px;
            &:focus, &:hover {border: 3px white solid}
          `}
        >
          <MarkerHeader
            css={css`
              margin: 0;
            `}
          >
            Out in Tech U Mentorship Program 2020 (Mentee)
          </MarkerHeader>
          <div>
            <ArticleDate>
            Pairing LGBTQ+ youth with tech industry professionals
                        </ArticleDate>
            <ul
              css={css`
                max-width: 310px;
                padding-top: 12px;
                margin: 0 auto;
              `}
            >
              <li>
              Participated in the eight-week, Spring 2020 program under the mentorship of a senior software engineer at Newsela
              </li>
              <li>
              Exercised common project development processes and engineering best practices via individual and pair programming, code reviews, and version control using Git
              </li>
              <li>
              Applied my learnings in my capstone project, TweetsCategorizer, which was developed in an iterative, Agile-based approach with weekly retrospectives and planning with my mentor
              </li>
            </ul>
          </div>
          {/* <img  css={css`justify-self: center`} alt="" src="" /> */}
        </a>

        <a
          href="https://github.com/priyil/TweetsCategorizer"
          css={css`
            text-decoration: none;
            color: inherit;
            display: grid;
            grid-row-gap: 8px;
            justify-content: center;
            text-align: center;
            margin-bottom: 16px;
            padding: 3px;
            &:focus, &:hover {border: 3px white solid}
          `}
        >
          <MarkerHeader
            css={css`
              margin: 0;
            `}
          >
            TweetsCategorizer
          </MarkerHeader>
          <div>
            <ArticleDate>
              Organizing app to categorize your Twitter likes
            </ArticleDate>
            <ul
              css={css`
                max-width: 310px;
                padding-top: 12px;
                margin: 0 auto;
              `}
            >
              <li>
                Built a React frontend/Spring backend web application that
                allows users to organize their likes into custom categories
              </li>
              <li>
                Integrated the Twitter4J Java library to use the Twitter API and
                allow sign-in via OAuth
              </li>
              <li>
                Allowed users to download their curated collections as CSV files
              </li>
            </ul>
          </div>
          {/* <img  css={css`justify-self: center`} alt="" src="" /> */}
        </a>
        <a
          href="https://github.com/priyil/InstaApp"
          css={css`
            text-decoration: none;
            color: inherit;
            display: grid;
            grid-row-gap: 8px;
            justify-content: center;
            text-align: center;
            padding: 3px;
            &:focus, &:hover {border: 3px white solid}
          `}
        >
          <MarkerHeader
            css={css`
              margin: 0;
            `}
          >
            InstaApp
          </MarkerHeader>
          <div>
            <ArticleDate>Photo-sharing app inspired by Instagram</ArticleDate>
          </div>
          <img
            css={css`
              justify-self: center;
            `}
            alt="Gif of InstaApp"
            src="https://raw.githubusercontent.com/priyil/InstaApp/master/walkthrough.gif"
          />
        </a>
        <a
          href="https://github.com/priyil/Flixster"
          css={css`
            text-decoration: none;
            color: inherit;
            display: grid;
            grid-row-gap: 8px;
            justify-content: center;
            text-align: center;
            padding: 3px;
            &:focus, &:hover {border: 3px white solid}
          `}
        >
          <MarkerHeader
            css={css`
              margin: 0;
            `}
          >
            Flixster
          </MarkerHeader>
          <div>
            <ArticleDate>
              Movie information app for the latest, most popular films
            </ArticleDate>
          </div>
          <img
            css={css`
              justify-self: center;
            `}
            alt="Gif of Flixster"
            src="https://raw.githubusercontent.com/priyil/Flixster/master/walkthrough1.gif"
          />
        </a>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
